import "@material/mwc-icon-button";
import type { IconButton } from "@material/mwc-icon-button";
import { css, CSSResultGroup, html, LitElement, TemplateResult } from "lit";
import { customElement, property, query } from "lit/decorators";
import "./taui-icon";

@customElement("taui-icon-button")
export class TauiIconButton extends LitElement {
  @property({ type: Boolean, reflect: true }) disabled = false;

  // icon path (if you need a non SVG icon instead, use the provided slot to pass an <taui-icon> in)
  @property({ type: String }) icon?: string;

  // Label that is used for ARIA support and as tooltip
  @property({ type: String }) label = "";

  @property({ type: Boolean }) hideTitle = false;

  @query("mwc-icon-button", true) private _button?: IconButton;

  public override focus() {
    this._button?.focus();
  }

  static shadowRootOptions: ShadowRootInit = {
    mode: "open",
    delegatesFocus: true,
  };

  protected render(): TemplateResult {
    // Note: `ariaLabel` required despite the `mwc-icon-button` docs saying `label` should be enough
    return html`
      <mwc-icon-button
        .ariaLabel=${this.label}
        .title=${this.hideTitle ? "" : this.label}
        .disabled=${this.disabled}
      >
        ${this.icon ? html` <taui-icon .icon=${this.icon}></taui-icon>` : ""}
        <slot></slot>
      </mwc-icon-button>
    `;
  }

  static get styles(): CSSResultGroup {
    return css`
      :host {
        display: inline-block;
        outline: none;
      }
      :host([disabled]) {
        pointer-events: none;
      }
      mwc-icon-button {
        --mdc-theme-on-primary: currentColor;
        --mdc-theme-text-disabled-on-light: var(--disabled-text-color);
      }
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "taui-icon-button": TauiIconButton;
  }
}
