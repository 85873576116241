import { Settings } from "luxon";
import { computeLocalize } from "../common/translations/localize";
import { translationMetadata } from "../resources/translations-metadata";
import { Constructor, TucanoAdminUI } from "../types";
import { storeState } from "../util/taui-pref-storage";
import { NumberFormat, TimeFormat } from "../data/translation";
import {
  getLocalLanguage,
  getTranslation,
  getUserLocale,
} from "../util/taui-translation";
import { TauiBaseEl } from "./taui-base-mixin";
import { fireEvent } from "../common/dom/fire_event";
import {
  computeRTLDirection,
  setDirectionStyles,
} from "../common/util/compute_rtl";

declare global {
  // for fire event
  interface TAUIDomEvents {
    "taui-language-select": string;
    "taui-number-format-select": {
      number_format: NumberFormat;
    };
    "taui-time-format-select": {
      time_format: TimeFormat;
    };
    "taui-time-zone-select": {
      time_zone: string;
    };
    "translations-updated": undefined;
  }
}

/*
 * superClass needs to contain `this.taui` and `this._updateTaui`.
 */

export default <T extends Constructor<TauiBaseEl>>(superClass: T) =>
  class extends superClass {
    // eslint-disable-next-line: variable-name
    private __coreProgress?: string;

    private __loadedFragmetTranslations: Set<string> = new Set();

    protected firstUpdated(changedProps) {
      super.firstUpdated(changedProps);
      this.addEventListener("taui-language-select", (e) =>
        this._selectLanguage((e as CustomEvent).detail)
      );
      this.addEventListener("taui-number-format-select", (e) => {
        this._selectNumberFormat((e as CustomEvent).detail);
      });
      this.addEventListener("taui-time-format-select", (e) => {
        this._selectTimeFormat((e as CustomEvent).detail);
      });
      this.addEventListener("taui-time-zone-select", (e) => {
        this._selectTimeZone((e as CustomEvent).detail);
      });
      this._loadCoreTranslations(getLocalLanguage());
    }

    protected tauiConnected() {
      super.tauiConnected();
      getUserLocale(this.taui!).then((locale) => {
        if (locale?.language && this.taui!.language !== locale.language) {
          // We just get language from backend, no need to save back
          this._selectLanguage(locale.language);
        }
        if (
          locale?.number_format &&
          this.taui!.locale.number_format !== locale.number_format
        ) {
          // We just got number_format from backend
          this._selectNumberFormat(locale.number_format);
        }
        if (
          locale?.time_format &&
          this.taui!.locale.time_format !== locale.time_format
        ) {
          // We just got time_format from backend, no need to save back
          this._selectTimeFormat(locale.time_format);
        }
        if (
          locale?.time_zone &&
          this.taui!.locale.time_zone !== locale.time_zone
        ) {
          this._selectTimeZone(locale.time_zone);
        }
      });
      this._applyTranslations(this.taui!);
    }

    protected tauiReconnected() {
      super.tauiReconnected();
      this._applyTranslations(this.taui!);
    }

    protected panelUrlChanged(newPanelUrl: string) {
      super.panelUrlChanged(newPanelUrl);

      // this may be triggered before tauiConnected
      this._loadFragmentTranslations(
        this.taui ? this.taui.language : getLocalLanguage(),
        newPanelUrl
      );
    }

    private _selectNumberFormat(number_format: NumberFormat) {
      this._updateTaui({
        locale: { ...this.taui!.locale, number_format: number_format },
      });
    }

    private _selectTimeFormat(time_format: TimeFormat) {
      this._updateTaui({
        locale: { ...this.taui!.locale, time_format: time_format },
      });
    }

    private _selectTimeZone(time_zone: string) {
      // Configure the default time zone
      Settings.defaultZone =
        time_zone === "browser"
          ? Intl.DateTimeFormat().resolvedOptions().timeZone
          : time_zone;

      this._updateTaui({
        locale: { ...this.taui!.locale, time_zone: time_zone },
      });
    }

    private _selectLanguage(language: string) {
      if (!this.taui) {
        // should not happen, do it to avoid use this.taui!
        return;
      }

      // update selectedLanguage so that it can be saved to local storage
      this._updateTaui({
        locale: { ...this.taui!.locale, language: language },
        language,
        selectedLanguage: language,
      });
      storeState(this.taui);

      this._applyTranslations(this.taui);
    }

    private _applyTranslations(taui: TucanoAdminUI) {
      document.querySelector("html")!.setAttribute("lang", taui.language);
      this._applyDirection(taui);
      this._loadCoreTranslations(taui.language);
      this.__loadedFragmetTranslations = new Set();
      this._loadFragmentTranslations(taui.language, taui.panelUrl);
    }

    private _applyDirection(taui: TucanoAdminUI) {
      const direction = computeRTLDirection(taui);
      document.dir = direction;
      setDirectionStyles(direction, this);
    }

    private async _loadFragmentTranslations(
      language: string,
      panelUrl: string
    ) {
      if (!panelUrl) {
        return;
      }

      const dividerPos = panelUrl.indexOf("/", 1);
      const fragmentPanelUrl =
        dividerPos === -1 ? panelUrl.substr(0) : panelUrl.substr(0, dividerPos);

      // If it's the first call we don't have panel info yet to check the component.
      // If the url is not known it might be a custom dashboard, so we load translations
      const fragment = translationMetadata.fragments.includes(
        fragmentPanelUrl || panelUrl
      )
        ? fragmentPanelUrl || panelUrl
        : !fragmentPanelUrl
        ? "identity"
        : undefined;

      if (!fragment) {
        return;
      }
      if (this.__loadedFragmetTranslations.has(fragment)) {
        return;
      }
      this.__loadedFragmetTranslations.add(fragment);
      const result = await getTranslation(fragment, language);
      await this._updateResources(result.language, result.data);
    }

    private async _loadCoreTranslations(language: string) {
      // Check if already in progress
      // Necessary as we call this in firstUpdated and tauiConnected
      if (this.__coreProgress === language) {
        return;
      }
      this.__coreProgress = language;
      try {
        const result = await getTranslation(null, language);
        await this._updateResources(result.language, result.data);
      } finally {
        this.__coreProgress = undefined;
      }
    }

    private async _updateResources(language: string, data: any) {
      // Update the language in taui, and update the resources with the newly
      // loaded resources. This merges the new data on top of the old data for
      // this language, so that the full translation set can be loaded across
      // multiple fragments.
      //
      // Beware of a subtle race condition: it is possible to get here twice
      // before this.taui is even created. In this case our base state comes
      // from this._pendingTaui instead. Otherwise the first set of strings is
      // overwritten when we call _updateTaui the second time!
      // Allow taui to be updated
      await new Promise((resolve) => setTimeout(resolve, 0));

      if (language !== (this.taui ?? this._pendingTaui).language) {
        // the language was changed, abort
        return;
      }

      const resources = {
        [language]: {
          ...(this.taui ?? this._pendingTaui)?.resources?.[language],
          ...data,
        },
      };
      const changes: Partial<TucanoAdminUI> = {
        resources,
        localize: await computeLocalize(this, language, resources),
      };

      if (language === (this.taui ?? this._pendingTaui).language) {
        this._updateTaui(changes);
      }
      fireEvent(this, "translations-updated");
    }
  };
