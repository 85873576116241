import { TucanoAdminUI } from "../../types";
import { AssemblingParams, MediaDTO } from "../../openapi/studio-api";
import { TauiServices } from "../../types/structure";

export const studioAssembleChunks = async (
  taui: TucanoAdminUI,
  mediaId: string,
  params: AssemblingParams
) =>
  taui.callApi<void>(
    "POST",
    TauiServices.studio,
    `/media/${mediaId}/upload/assemble`,
    "studio.catalog.update",
    params
  );

export const studioResetUpload = async (taui: TucanoAdminUI, mediaId: string) =>
  taui.callApi<MediaDTO>(
    "PUT",
    TauiServices.studio,
    `/media/${mediaId}/upload/reset`,
    "studio.catalog.update"
  );
