import { dom, icon, library } from "@fortawesome/fontawesome-svg-core";
import { css, CSSResultGroup, html, LitElement, PropertyValues } from "lit";
import { customElement, property } from "lit/decorators";

@customElement("taui-fontawesome")
export class TauiFontawesome extends LitElement {
  @property() public icon?: string;

  @property() public path?: string;

  @property() public viewBox?: string;

  @property() public fixedWidth?: boolean;

  @property() public spin?: boolean;

  protected firstUpdated() {
    const faStyles = document.createElement("style");
    faStyles.innerHTML = dom.css();
    this.shadowRoot!.appendChild(faStyles);
  }

  protected updated(changedProps: PropertyValues) {
    if (changedProps.has("path")) {
      this._loadIcon();
    }
  }

  render() {
    return html` <div id="fa-icon"></div> `;
  }

  private _loadIcon() {
    const [iconPrefix, origIconName] = this.icon!.split(":", 2);

    if (this.viewBox) {
      const [_x, _y, w, h] = this.viewBox!.split(" ", 4);

      const iconDef = {
        prefix: iconPrefix,
        iconName: origIconName,
        icon: [w, h, [], "", this.path],
      };

      // @ts-ignore
      library.add(iconDef);

      const options: any = { classes: [] };

      if (this.fixedWidth) {
        options.classes.push("fa-fw");
      }

      if (this.spin) {
        options.classes.push("fa-spin");
      }

      // @ts-ignore
      this.shadowRoot.getElementById("fa-icon").innerHTML = icon(
        // @ts-ignore
        iconDef,
        options
      ).html;
    }
  }

  static get styles(): CSSResultGroup {
    return css`
      #fa-icon {
        font-size: var(--mdc-icon-size, 1em);
      }
    `;
  }
}
declare global {
  interface HTMLElementTagNameMap {
    "taui-fontawesome": TauiFontawesome;
  }
}
