export function getInitials(name): string {
  if (name) {
    let initials: any = name.match(/\b\w/g) || [];
    initials = (
      (initials.shift() || "") + (initials.pop() || "")
    ).toUpperCase();

    return initials;
  }

  return "";
}
