import { TucanoAdminUI } from "../../types";
import {
  IdentityDTO,
  IdentityParams,
  PasswordPolicyDTO,
  ResponseDTO,
} from "../../openapi/identity-api";
import { TauiServices } from "../../types/structure";

export const identityQueryIdentities = async (
  taui: TucanoAdminUI,
  params?: any
) =>
  taui.callApi<ResponseDTO & { result?: IdentityDTO[] }>(
    "GET",
    TauiServices.identity,
    "/identity",
    "identity.identity.list",
    params
  );

export const identityGetIdentity = async (
  taui: TucanoAdminUI,
  identityId,
  params?
) =>
  taui.callApi<ResponseDTO & { result?: IdentityDTO }>(
    "GET",
    TauiServices.identity,
    `/identity/${identityId}`,
    taui.auth.idIdentity === identityId
      ? "identity.identity.own.read"
      : "identity.identity.read",
    params
  );

export const identityCreateIdentityAndUser = async (
  taui: TucanoAdminUI,
  params: IdentityParams
) =>
  taui.callApi<ResponseDTO & { result?: IdentityDTO }>(
    "POST",
    TauiServices.identity,
    "/identity",
    "identity.identity.user.update",
    params
  );

export const identityUpdateIdentity = async (
  taui: TucanoAdminUI,
  identityId,
  params: IdentityParams
) =>
  taui.callApi<ResponseDTO & { result?: IdentityDTO }>(
    "PUT",
    TauiServices.identity,
    `/identity/${identityId}`,
    taui.auth.idIdentity === identityId
      ? "identity.identity.user.own.update"
      : "identity.identity.user.update",
    params
  );

export const identityPatchIdentity = async (
  taui: TucanoAdminUI,
  identityId,
  params: IdentityParams
) =>
  taui.callApi<ResponseDTO & { result?: IdentityDTO }>(
    "PATCH",
    TauiServices.identity,
    `/identity/${identityId}`,
    taui.auth.idIdentity === identityId
      ? "identity.identity.user.own.update"
      : "identity.identity.user.update",
    params
  );

export const identityDeleteIdentity = async (taui: TucanoAdminUI, identityId) =>
  taui.callApi<ResponseDTO & { result?: Record<string, unknown> | null }>(
    "DELETE",
    TauiServices.identity,
    `/identity/${identityId}`,
    "identity.identity.delete"
  );

export const identityGetMergedPasswordPolicy = async (
  taui: TucanoAdminUI,
  idIdentity
) =>
  taui.callApi<ResponseDTO & { result?: PasswordPolicyDTO }>(
    "GET",
    TauiServices.identity,
    `/identity/${idIdentity}/passwordpolicy`,
    "identity.password-policy.list"
  );
