export const LAZY_LOAD_ROUTES = {
  /* Dashboard */
  paneldashboard: () => import("../panels/dashboard/taui-panel-dashboard"),

  /* System */
  systemconfiguration: () =>
    import("../panels/system/system-configuration/taui-system-configuration"),
  rolelist: () => import("../panels/system/role/taui-role-list"),
  role: () => import("../panels/system/role/edit/taui-role"),
  resourcegrouplist: () =>
    import("../panels/system/resource-group/taui-resource-group-list"),
  resourcegroup: () =>
    import("../panels/system/resource-group/edit/taui-resource-group"),
  userlist: () => import("../panels/system/user/taui-user-list"),
  user: () => import("../panels/system/user/edit/taui-user"),
  version: () => import("../panels/system/version/taui-version"),

  /* CMS */
  /* Live */
  channellist: () => import("../panels/cms/live/channel/taui-channel-list"),
  channel: () => import("../panels/cms/live/channel/edit/taui-channel"),
  serviceplanlist: () =>
    import("../panels/cms/live/service-plan/taui-service-plan-list"),
  serviceplan: () =>
    import("../panels/cms/live/service-plan/edit/taui-service-plan"),
  epgeventlist: () =>
    import("../panels/cms/live/epg-event/taui-epg-event-list"),
  epgevent: () => import("../panels/cms/live/epg-event/edit/taui-epg-event"),
  epgproviderlist: () =>
    import("../panels/cms/live/epg-provider/taui-epg-provider-list"),
  epgprovider: () =>
    import("../panels/cms/live/epg-provider/edit/taui-epg-provider"),
  /* Live stream package */
  livestreampackagelist: () =>
    import(
      "../panels/cms/live/live-stream-package/taui-live-stream-package-list"
    ),
  livestreampackage: () =>
    import(
      "../panels/cms/live/live-stream-package/edit/taui-live-stream-package"
    ),

  /* VOD */
  vodcataloglist: () =>
    import("../panels/cms/vod/vod-catalog/taui-vod-catalog-list"),
  vodcatalogpublication: () =>
    import(
      "../panels/cms/vod/vod-catalog/edit/publication/taui-vod-catalog-publication"
    ),
  vodcatalogsource: () =>
    import("../panels/cms/vod/vod-catalog/edit/source/taui-vod-catalog-source"),
  vodcategory: () =>
    import("../panels/cms/vod/vod-category/edit/taui-vod-category"),
  vodcategorydetails: () =>
    import("../panels/cms/vod/vod-category/edit/taui-vod-category-details"),
  vodasset: () => import("../panels/cms/vod/vod-asset/edit/taui-vod-asset"),
  vodpackagelist: () =>
    import("../panels/cms/vod/vod-package/taui-vod-package-list"),
  vodpackage: () =>
    import("../panels/cms/vod/vod-package/edit/taui-vod-package"),
  studio: () => import("../panels/cms/vod/studio/taui-studio"),
  studioasset: () => import("../panels/cms/vod/studio/asset/taui-studio-asset"),
  studiocategory: () =>
    import("../panels/cms/vod/studio/category/taui-studio-category"),
  studioseries: () =>
    import("../panels/cms/vod/studio/series/taui-studio-series"),
  studioseason: () =>
    import("../panels/cms/vod/studio/season/taui-studio-season"),

  /* Series */
  serieslist: () => import("../panels/cms/series/series/taui-series-list"),
  series: () => import("../panels/cms/series/series/edit/taui-series"),

  /* Seasons */
  season: () => import("../panels/cms/series/seasons/edit/taui-season"),

  /* Recommendation */
  recommendation: () =>
    import("../panels/cms/recommendation/taui-recommendation"),

  /* CRM */
  /* CRM Configuration */
  crmconfiguration: () =>
    import("../panels/crm/crm-configuration/taui-crm-configuration"),

  /* Customer */
  customerlist: () => import("../panels/crm/customer/taui-customer-list"),
  customer: () => import("../panels/crm/customer/edit/taui-customer"),
  customeradministrative: () =>
    import("../panels/crm/customer/edit/taui-customer-administrative"),
  customerprofiles: () =>
    import("../panels/crm/customer/edit/taui-customer-profiles"),
  customersubscriptions: () =>
    import("../panels/crm/customer/edit/taui-customer-subscriptions"),
  customerorders: () =>
    import("../panels/crm/customer/edit/taui-customer-orders"),
  customersupport: () =>
    import("../panels/crm/customer/edit/taui-customer-support"),
  customernotificationhistory: () =>
    import("../panels/crm/customer/edit/taui-customer-notification-history"),

  /* Publication */
  /* Portal */
  portallist: () => import("../panels/publication/portal/taui-portal-list"),
  portal: () => import("../panels/publication/portal/edit/taui-portal"),

  /* Client */
  clientlist: () => import("../panels/publication/client/taui-client-list"),
  client: () => import("../panels/publication/client/edit/taui-client"),

  /* Homepage */
  homepagelist: () =>
    import("../panels/publication/homepage/taui-homepage-list"),
  homepage: () => import("../panels/publication/homepage/edit/taui-homepage"),

  /* Global Image */
  globalimagelist: () =>
    import("../panels/publication/global-image/taui-global-image-list"),

  /* Editorial section */
  editorialsectionlist: () =>
    import(
      "../panels/publication/editorial-section/taui-editorial-section-list"
    ),
  editorialsectionlegacyfaq: () =>
    import(
      "../panels/publication/editorial-section/legacy-faq/taui-editorial-section-legacy-faq"
    ),
  editorialsectionnewsection: () =>
    import(
      "../panels/publication/editorial-section/new-section/taui-editorial-section-new-section"
    ),

  /* Exporter */
  publicationexporter: () =>
    import("../panels/publication/exporter/taui-publication-exporter"),

  /* Monetization */
  /* Brands */
  monetizationbrandlist: () =>
    import("../panels/monetization/brand/taui-monetization-brand-list"),
  monetizationbrand: () =>
    import("../panels/monetization/brand/edit/taui-monetization-brand"),
  /* Offers */
  monetizationofferlist: () =>
    import("../panels/monetization/offer/taui-monetization-offer-list"),
  monetizationoffer: () =>
    import("../panels/monetization/offer/edit/taui-monetization-offer"),
  /* Offer groups */
  monetizationoffergroupslist: () =>
    import(
      "../panels/monetization/offer-groups/taui-monetization-offer-groups-list"
    ),
  monetizationoffergroups: () =>
    import(
      "../panels/monetization/offer-groups/edit/taui-monetization-offer-groups"
    ),

  /* Discount */
  monetizationdiscountlist: () =>
    import("../panels/monetization/discount/taui-monetization-discount-list"),
  monetizationdiscount: () =>
    import("../panels/monetization/discount/edit/taui-monetization-discount"),

  /* Order */
  monetizationorderlist: () =>
    import("../panels/monetization/order/taui-monetization-order-list"),

  /* Communication */
  /* Notification template */
  communicationnotificationtemplatelist: () =>
    import(
      "../panels/communication/notification/template/taui-communication-notification-template-list"
    ),
  communicationnotificationtemplate: () =>
    import(
      "../panels/communication/notification/template/edit/taui-communication-notification-template"
    ),
  /* Notification history */
  communicationnotificationhistorylist: () =>
    import(
      "../panels/communication/notification/history/taui-communication-notification-history-list"
    ),
  communicationnotificationhistory: () =>
    import(
      "../panels/communication/notification/history/edit/taui-communication-notification-history"
    ),

  /* Realtime */
  /* Message */
  realtimemessagelist: () =>
    import("../panels/realtime/message/taui-realtime-message-list"),
  realtimemessage: () =>
    import("../panels/realtime/message/edit/taui-realtime-message"),

  /* Support */
  /* Entitlements */
  supportentitlementslist: () =>
    import("../panels/support/entitlements/taui-support-entitlements-list"),
  supportentitlements: () =>
    import("../panels/support/entitlements/edit/taui-support-entitlements"),
};
