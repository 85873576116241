import { TucanoAdminUI } from "../../types";
import { TauiServices } from "../../types/structure";

export const getPermission = async (taui: TucanoAdminUI) =>
  taui.callApi<{
    result: string[];
  }>("GET", TauiServices.identity, "/permission", "identity.permission.list");

export const getCurrentPermission = async (taui: TucanoAdminUI) =>
  taui.callApi<{
    result: string[];
  }>("GET", TauiServices.identity, "/permission/current");
