export function generateKeyTab(
  componentName: string,
  id: any,
  allowMultiple?: boolean
) {
  return `${componentName}${
    id && typeof id !== "object"
      ? !isNaN(id)
        ? Math.abs(id).toString(10)
        : id
      : allowMultiple && typeof id !== "object"
      ? new Date().getTime().toString(10)
      : ""
  }`;
}
