import { css } from "lit";

export const darkStyles = {
  "primary-background-color": "#111111",
  "card-background-color": "#1c1c1c",
  "secondary-background-color": "#202020",
  "primary-text-color": "#e1e1e1",
  "secondary-text-color": "#9b9b9b",
  "disabled-text-color": "#6f6f6f",
  "app-header-text-color": "#e1e1e1",
  "app-header-background-color": "#101e24",
  "switch-unchecked-button-color": "#999999",
  "switch-unchecked-track-color": "#9b9b9b",
  "divider-color": "rgba(225, 225, 225, .12)",
  "mdc-ripple-color": "#AAAAAA",

  "input-idle-line-color": "rgba(255, 255, 255, 0.42)",
  "input-hover-line-color": "rgba(255, 255, 255, 0.87)",
  "input-disabled-line-color": "rgba(255, 255, 255, 0.06)",
  "input-outlined-idle-border-color": "rgba(255, 255, 255, 0.38)",
  "input-outlined-hover-border-color": "rgba(255, 255, 255, 0.87)",
  "input-outlined-disabled-border-color": "rgba(255, 255, 255, 0.06)",
  "input-fill-color": "rgba(255, 255, 255, 0.05)",
  "input-disabled-fill-color": "rgba(255, 255, 255, 0.02)",
  "input-ink-color": "rgba(255, 255, 255, 0.87)",
  "input-label-ink-color": "rgba(255, 255, 255, 0.6)",
  "input-disabled-ink-color": "rgba(255, 255, 255, 0.37)",
  "input-dropdown-icon-color": "rgba(255, 255, 255, 0.54)",

  "sidebar-background-color": "#1d1e1c",
  "app-edited-field-color": "rgba(251, 255, 0, .12)",
};

export const derivedStyles = {
  "error-state-color": "var(--error-color)",
  "sidebar-text-color": "var(--text-primary-color)",
  "sidebar-background-color": "#2c3e50",
  "sidebar-selected-text-color": "var(--text-primary-color)",
  "sidebar-selected-icon-color": "var(--text-primary-color)",
  "sidebar-icon-color": "var(--text-primary-color)",
  "switch-checked-color": "var(--primary-color)",
  "switch-checked-button-color":
    "var(--switch-checked-color, var(--primary-background-color))",
  "switch-checked-track-color": "var(--switch-checked-color, #000000)",
  "switch-unchecked-button-color":
    "var(--switch-unchecked-color, var(--primary-background-color))",
  "switch-unchecked-track-color": "var(--switch-unchecked-color, #000000)",
  "label-badge-grey": "var(--paper-grey-500)",
  "label-badge-background-color": "#8664b7",
  "label-badge-text-color": "#fff",
  "player-bar-background-color": "var(--sidebar-background-color)",
  "player-text-color": "var(--text-primary-color)",
  "player-border-color": "var(--scrollbar-track-color)",
  "paper-listbox-background-color": "var(--card-background-color)",
  "paper-item-icon-color": "var(--state-icon-color)",
  "paper-item-icon-active-color": "var(--state-icon-active-color)",
  "table-row-background-color": "var(--primary-background-color)",
  "table-row-alternative-background-color": "var(--secondary-background-color)",
  "data-table-background-color": "var(--card-background-color)",
  "markdown-code-background-color": "var(--primary-background-color)",

  // https://github.com/material-components/material-web/blob/master/docs/theming.md
  "mdc-theme-primary": "var(--primary-color)",
  "mdc-theme-secondary": "var(--accent-color)",
  "mdc-theme-background": "var(--primary-background-color)",
  "mdc-theme-surface": "var(--card-background-color)",
  "mdc-theme-on-primary": "var(--text-primary-color)",
  "mdc-theme-on-secondary": "var(--text-primary-color)",
  "mdc-theme-on-surface": "var(--primary-text-color)",
  "mdc-theme-text-primary-on-background": "var(--primary-text-color)",
  "mdc-theme-text-secondary-on-background": "var(--secondary-text-color)",
  "mdc-theme-text-icon-on-background": "var(--secondary-text-color)",
  "mdc-theme-error": "var(--error-color)",
  "app-header-text-color": "var(--primary-text-color)",
  "app-header-background-color": "var(--primary-background-color)",
  "app-header-toolbar-background-color": "var(--card-background-color)",
  "mdc-checkbox-unchecked-color": "rgba(var(--rgb-primary-text-color), 0.54)",
  "mdc-checkbox-disabled-color": "var(--disabled-text-color)",
  "mdc-radio-unchecked-color": "rgba(var(--rgb-primary-text-color), 0.54)",
  "mdc-radio-disabled-color": "var(--disabled-text-color)",
  "mdc-tab-text-label-color-default": "var(--primary-text-color)",
  "mdc-button-disabled-ink-color": "var(--disabled-text-color)",
  "mdc-button-outline-color": "var(--divider-color)",
  "mdc-dialog-scroll-divider-color": "var(--divider-color)",

  "mdc-text-field-idle-line-color": "var(--input-idle-line-color)",
  "mdc-text-field-hover-line-color": "var(--input-hover-line-color)",
  "mdc-text-field-disabled-line-color": "var(--input-disabled-line-color)",
  "mdc-text-field-outlined-idle-border-color":
    "var(--input-outlined-idle-border-color)",
  "mdc-text-field-outlined-hover-border-color":
    "var(--input-outlined-hover-border-color)",
  "mdc-text-field-outlined-disabled-border-color":
    "var(--input-outlined-disabled-border-color)",
  "mdc-text-field-fill-color": "var(--input-fill-color)",
  "mdc-text-field-disabled-fill-color": "var(--input-disabled-fill-color)",
  "mdc-text-field-ink-color": "var(--input-ink-color)",
  "mdc-text-field-label-ink-color": "var(--input-label-ink-color)",
  "mdc-text-field-disabled-ink-color": "var(--input-disabled-ink-color)",

  "mdc-select-idle-line-color": "var(--input-idle-line-color)",
  "mdc-select-hover-line-color": "var(--input-hover-line-color)",
  "mdc-select-outlined-idle-border-color":
    "var(--input-outlined-idle-border-color)",
  "mdc-select-outlined-hover-border-color":
    "var(--input-outlined-hover-border-color)",
  "mdc-select-outlined-disabled-border-color":
    "var(--input-outlined-disabled-border-color)",
  "mdc-select-fill-color": "var(--input-fill-color)",
  "mdc-select-disabled-fill-color": "var(--input-disabled-fill-color)",
  "mdc-select-ink-color": "var(--input-ink-color)",
  "mdc-select-label-ink-color": "var(--input-label-ink-color)",
  "mdc-select-disabled-ink-color": "var(--input-disabled-ink-color)",
  "mdc-select-dropdown-icon-color": "var(--input-dropdown-icon-color)",
  "mdc-select-disabled-dropdown-icon-color": "var(--input-disabled-ink-color)",

  "chip-background-color": "rgba(var(--rgb-primary-text-color), 0.15)",
  // Vaadin
  "material-body-text-color": "var(--primary-text-color)",
  "material-background-color": "var(--card-background-color)",
  "material-secondary-background-color": "var(--secondary-background-color)",
  "material-secondary-text-color": "var(--secondary-text-color)",
  "item-selected-background": "var(--divider-color)",
};

export const buttonLinkStyle = css`
  button.link {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    text-align: left;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const tauiStyle = css`
  :host {
    font-family: var(--paper-font-body1_-_font-family);
    -webkit-font-smoothing: var(--paper-font-body1_-_-webkit-font-smoothing);
    font-size: var(--paper-font-body1_-_font-size);
    font-weight: var(--paper-font-body1_-_font-weight);
    line-height: var(--paper-font-body1_-_line-height);
  }

  h1 {
    font-family: var(--paper-font-headline_-_font-family);
    -webkit-font-smoothing: var(--paper-font-headline_-_-webkit-font-smoothing);
    white-space: var(--paper-font-headline_-_white-space);
    overflow: var(--paper-font-headline_-_overflow);
    text-overflow: var(--paper-font-headline_-_text-overflow);
    font-size: var(--paper-font-headline_-_font-size);
    font-weight: var(--paper-font-headline_-_font-weight);
    line-height: var(--paper-font-headline_-_line-height);
  }

  h2 {
    font-family: var(--paper-font-title_-_font-family);
    -webkit-font-smoothing: var(--paper-font-title_-_-webkit-font-smoothing);
    white-space: var(--paper-font-title_-_white-space);
    overflow: var(--paper-font-title_-_overflow);
    text-overflow: var(--paper-font-title_-_text-overflow);
    font-size: var(--paper-font-title_-_font-size);
    font-weight: var(--paper-font-title_-_font-weight);
    line-height: var(--paper-font-title_-_line-height);
  }

  h3 {
    font-family: var(--paper-font-subhead_-_font-family);
    -webkit-font-smoothing: var(--paper-font-subhead_-_-webkit-font-smoothing);
    white-space: var(--paper-font-subhead_-_white-space);
    overflow: var(--paper-font-subhead_-_overflow);
    text-overflow: var(--paper-font-subhead_-_text-overflow);
    font-size: var(--paper-font-subhead_-_font-size);
    font-weight: var(--paper-font-subhead_-_font-weight);
    line-height: var(--paper-font-subhead_-_line-height);
  }

  .primary {
    color: var(--paper-button-color);
  }

  .error {
    color: var(--error-color);
  }

  .success {
    color: var(--success-color);
  }

  .warning {
    color: var(--warning-color);
  }

  mwc-button,
  mwc-button.primary {
    --mdc-theme-primary: var(--paper-button-color);
  }
  mwc-button[raised],
  mwc-button[raised].primary {
    --mdc-theme-primary: var(--paper-button-color);
    --mdc-theme-on-primary: white;
  }
  taui-icon-button.primary {
    color: var(--paper-button-color);
  }
  taui-fab.primary {
    --mdc-theme-secondary: var(--paper-button-color);
  }

  mwc-button[raised].accent {
    --mdc-theme-primary: var(--accent-color);
    --mdc-theme-on-primary: white;
  }
  taui-icon-button.accent {
    color: var(--accent-color);
  }

  mwc-button[raised].success {
    --mdc-theme-primary: var(--success-color);
    --mdc-theme-on-primary: white;
  }
  taui-icon-button.success {
    color: var(--success-color);
  }

  mwc-button.error {
    --mdc-theme-primary: var(--error-color);
  }
  mwc-button[raised].error {
    --mdc-theme-on-primary: white;
  }
  taui-icon-button.error {
    color: var(--error-color);
  }

  mwc-button[raised].warning {
    --mdc-theme-primary: var(--warning-color);
    --mdc-theme-on-primary: white;
  }
  taui-icon-button.warning {
    color: var(--warning-color);
  }

  mwc-button[raised].none {
    --mdc-theme-primary: var(--secondary-text-color);
    --mdc-theme-on-primary: white;
  }
  taui-icon-button.none {
    color: var(--secondary-text-color);
  }

  ${buttonLinkStyle}

  .layout.horizontal,
  .layout.vertical {
    display: flex;
  }
  .layout.inline {
    display: inline-flex;
  }
  .layout.horizontal {
    flex-direction: row;
  }
  .layout.vertical {
    flex-direction: column;
  }
  .layout.wrap {
    flex-wrap: wrap;
  }
  .layout.no-wrap {
    flex-wrap: nowrap;
  }
  .layout.center,
  .layout.center-center {
    align-items: center;
  }
  .layout.bottom {
    align-items: flex-end;
  }
  .layout.center-justified,
  .layout.center-center {
    justify-content: center;
  }
  .flex {
    flex: 1;
    flex-basis: 0.000000001px;
  }
  .flex-auto {
    flex: 1 1 auto;
  }
  :host([narrow]) .layout .flex-auto {
    width: 100%;
  }
  .flex-none {
    flex: none;
  }
  .layout.justified {
    justify-content: space-between;
  }
  .layout.gap {
    gap: 12px;
  }
  .layout .fixed-width {
    width: 400px;
  }
  :host([narrow]) .layout .fixed-width {
    width: 100%;
  }

  /* Container */

  .container {
    display: flex;
    flex-wrap: wrap;
    margin: 10px -10px 0 -10px;
  }

  .container .content {
    margin: 10px;
  }

  /* Element */

  .element {
    background: var(
      --taui-card-background,
      var(--card-background-color, white)
    );
    border-radius: var(--taui-card-border-radius, 4px);
    box-shadow: var(
      --taui-card-box-shadow,
      0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 1px 3px 0px rgba(0, 0, 0, 0.12)
    );
    color: var(--primary-text-color);
    display: block;
    transition: all 0.3s ease-out;
    position: relative;
  }

  .element.error {
    border: 2px solid var(--error-color);
  }

  taui-subpage .content,
  taui-tabs-subpage .content {
    display: block;
    margin: 10px 16px;
  }
  taui-subpage[narrow] .content,
  taui-tabs-subpage[narrow] .content {
    padding-bottom: 68px;
  }

  .container .element {
    min-height: 100px;
    padding-bottom: 5px;
    box-sizing: border-box;
  }

  .element.medium,
  .container .element.medium {
    width: 67%;
  }

  .element.full,
  .container .element.full {
    width: 100%;
  }

  .element.fullHeight,
  .container .element.fullHeight {
    height: 100%;
  }

  .container .element.imbricated {
    margin: 10px;
    width: calc(100% - 22px);
  }

  .container .element.collapse {
    min-height: 0;
    margin: 0 0 5px 0;
    border: none;
  }

  /* Title Content */

  .titleContent {
    position: relative;
    padding: 5px 0 0 0;
    white-space: nowrap;
    overflow: hidden;
    border-bottom: 1px solid var(--divider-color);
  }

  .element .titleContent {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 10px;
    white-space: nowrap;
    overflow: hidden;
    border-bottom: 1px solid var(--divider-color);
  }

  .element .titleContent.flexible {
    height: auto;
  }

  /* Title */

  .title {
    color: var(--primary-text-color);
    float: left;
    font-size: 16pt;
  }

  .titleContent .title {
    line-height: 42px;
  }

  .titleContent title {
    display: block;
    line-height: 44px;
    font-size: 16pt;
  }

  .element title {
    display: block;
    height: 24px;
    line-height: 24px;
    padding: 10px;
    border-bottom: 1px solid var(--divider-color);
  }

  .element .titleContent title {
    float: left;
    padding: 10px 0;
    border-bottom: none;
    font-size: 12pt;
  }

  /* Tabs */

  paper-tabs {
    margin: 10px 0 10px 0;
    border: 1px solid var(--divider-color);
    border-radius: 4px;
    --paper-tabs-selection-bar-color: var(
      --app-header-selection-bar-color,
      var(--primary-color, #fff)
    );
  }

  paper-tab {
    color: var(--primary-text-color);
  }

  paper-tab.error {
    border-bottom: 2px solid var(--error-color);
    transition: transform 0.25s;
    overflow: visible;
  }

  paper-tab .errorCount {
    display: none;
  }

  paper-tab.error .errorCount {
    display: block;
    width: 15px;
    height: 15px;
    line-height: 15px;
    margin: 0 0 0 5px;
    background-color: var(--accent-color);
    color: var(--text-accent-color, var(--text-primary-color));
    border-radius: 3px;
    font-size: 10pt;
    text-align: center;
    text-decoration: none;
  }

  /* Label */
  .element > span.error,
  .tableElement > span.error {
    line-height: 16px;
    margin: 5px 0 0 0;
    color: var(--error-color);
    font-weight: bold;
  }

  .element > span.error {
    padding-left: 10px;
  }

  /* Span */

  span.label taui-icon {
    height: 16px;
  }

  /* Input */

  vaadin-combo-box.error,
  taui-combo-box-polymer.error,
  taui-file-upload.error,
  taui-form-driver.error,
  .mdc-data-table.error {
    border: 1px solid var(--error-color) !important;
    background-color: #ff3d331a;
  }

  .mdc-data-table.error .table tbody tr td,
  .mdc-data-table .table tbody tr.error {
    background-color: #ff3d331a;
  }

  taui-combo-box:not([own-margin]) {
    margin-bottom: 12px;
  }

  .edited {
    background-color: var(--app-edited-field-color);
  }

  /* Item */

  paper-item {
    font-size: 11pt;
    color: var(--primary-text-color);
    --paper-item-focused-before: {
      background: transparent;
    }
  }

  /* Button */
  .titleContent .titleButtons {
    color: var(--primary-text-color);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;
  }

  .titleContent .buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;
    padding: 0;
    gap: 5px;
    flex: auto;
  }

  /* Font Awesome Icon */

  taui-icon.red {
    color: var(--error-color) !important;
  }

  taui-icon.green {
    color: var(--success-color) !important;
  }

  taui-icon.info {
    color: var(--info-color) !important;
  }

  taui-icon.warning {
    color: var(--warning-color) !important;
  }

  /* Tooltip */

  paper-tooltip {
    --paper-tooltip: {
      font-size: 0.9em;
      line-height: 1.2em;
    }
  }

  /* Toast - toastRefresh */

  taui-toast#toastRefresh {
    max-width: 500px !important;
    max-height: none !important;
  }

  /* Components */

  .container .element taui-table {
    padding: 10px;
  }

  /* Drag and Drop */

  .dropPreviewContainer {
    display: none;
  }

  .dropPreview {
    background-color: var(--secondary-background-color);
    border: 2px dashed var(--divider-color);
    border-radius: 3px;
    box-sizing: border-box;
    height: 100%;
  }

  /* Loader */
  taui-linear-progress {
    --mdc-linear-progress-buffer-color: rgba(200, 200, 200, 0.5);
  }

  taui-linear-progress:not([active]) {
    --mdc-linear-progress-buffer-color: transparent;
  }

  /* Responsive */

  .col-md-6,
  .col-md-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-12 {
    width: 100%;
    padding: 0 10px 20px 10px;
    box-sizing: border-box;
  }

  @media (min-width: 768px) {
    .col-md-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    .col-md-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  @media (min-width: 1200px) {
    .col-xl-2 {
      flex: 0 0 16.666666667%;
      max-width: 16.666666667%;
    }

    .col-xl-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }

    .col-xl-4 {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }

    .col-xl-5 {
      flex: 0 0 41.666666667%;
      max-width: 41.666666667%;
    }

    .col-xl-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    .col-xl-8 {
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }

    .col-xl-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }

    .col-xl-10 {
      flex: 0 0 83.333333333%;
      max-width: 83.333333333%;
    }

    .col-xl-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  /* Scrollbar */

  ::-webkit-scrollbar {
    width: 0.5rem; /* for vertical scrollbars - 8px */
    height: 0.5rem; /* for horizontal scrollbars - 8px */
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 4px;
    border-radius: 4px;
    background: var(--scrollbar-thumb-color);
  }

  ::-webkit-scrollbar-track {
    -webkit-border-radius: 4px;
    border-radius: 4px;
    background-color: var(--scrollbar-track-color);
  }

  /* Other */

  dom-if {
    display: none;
  }
`;

export const tauiStyleDialog = css`
  /* mwc-dialog (taui-dialog) styles */
  taui-dialog {
    --mdc-dialog-min-width: 400px;
    --mdc-dialog-max-width: 600px;
    --mdc-dialog-heading-ink-color: var(--primary-text-color);
    --mdc-dialog-content-ink-color: var(--primary-text-color);
  }

  taui-dialog .form {
    color: var(--primary-text-color);
  }

  a {
    color: var(--primary-color);
  }

  /* make dialog fullscreen on small screens */
  @media all and (max-width: 450px), all and (max-height: 500px) {
    taui-dialog {
      --mdc-dialog-min-width: calc(
        100vw - env(safe-area-inset-right) - env(safe-area-inset-left)
      );
      --mdc-dialog-max-width: calc(
        100vw - env(safe-area-inset-right) - env(safe-area-inset-left)
      );
      --mdc-dialog-min-height: 100%;
      --mdc-dialog-max-height: 100%;
      --vertial-align-dialog: flex-end;
      --taui-dialog-border-radius: 0px;
    }
  }
  mwc-button.warning {
    --mdc-theme-primary: var(--error-color);
  }
  .error {
    color: var(--error-color);
  }
`;

export const tauiStyleSideSheet = css`
  taui-side-sheet {
    --taui-side-sheet-min-width: 400px;
    --taui-side-sheet-max-width: 400px;
  }

  /* make side sheet fullscreen on small screens */
  @media all and (max-width: 450px) {
    taui-side-sheet {
      --taui-side-sheet-min-width: calc(
        100vw - env(safe-area-inset-right) - env(safe-area-inset-left) - 20px
      );
      --taui-side-sheet-max-width: calc(
        100vw - env(safe-area-inset-right) - env(safe-area-inset-left) - 20px
      );
    }
  }
`;

export const tauiStyleScrollbar = css`
  .taui-scrollbar::-webkit-scrollbar {
    width: 0.5rem; /* for vertical scrollbars - 8px */
    height: 0.5rem; /* for horizontal scrollbars - 8px */
  }

  .taui-scrollbar::-webkit-scrollbar-thumb {
    -webkit-border-radius: 4px;
    border-radius: 4px;
    background: var(--scrollbar-thumb-color);
  }

  .taui-scrollbar {
    overflow-y: auto;
    scrollbar-color: var(--scrollbar-thumb-color) transparent;
    scrollbar-width: thin;
  }

  .taui-scrollbar::-webkit-scrollbar-track {
    -webkit-border-radius: 4px;
    border-radius: 4px;
    background-color: var(--scrollbar-track-color);
  }
`;

export const tauiStyleHiddenScrollbar = css`
  .taui-hidden-scrollbar::-webkit-scrollbar {
    display: none; /*Chrome, Safari, Edge*/
    width: 0px; /* remove scrollbar space */
    height: 0px; /* remove scrollbar space */
    background: transparent; /* optional: just make scrollbar invisible */
  }

  .taui-hidden-scrollbar {
    /* Hidden scrollbar */
    scrollbar-visibility: hidden;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
  }
`;

export const tauiStyleImage = css`
  .pictureFull {
    min-width: 200px;
    min-height: 110px;
  }

  .previewTransparent {
    background-color: var(--primary-background-color);
    background-image: linear-gradient(
        45deg,
        #edf2f7 25%,
        transparent 25%,
        transparent 75%,
        #edf2f7 75%,
        #edf2f7
      ),
      linear-gradient(
        45deg,
        #edf2f7 25%,
        transparent 25%,
        transparent 75%,
        #edf2f7 75%,
        #edf2f7
      );
    background-position: 0 0, 10px 10px;
    background-size: 21px 21px;
  }
`;
