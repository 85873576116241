export function isString(type: string) {
  return type
    ? type.toLowerCase() === "string" || type.toLowerCase() === "text"
    : false;
}

export function isNumber(type: string) {
  return type ? type.toLowerCase() === "number" : false;
}

export function isInteger(type: string) {
  return type ? type.toLowerCase() === "integer" : false;
}

export function isFloat(type: string) {
  return type ? type.toLowerCase() === "float" : false;
}

export function isBoolean(type: string) {
  return type ? type.toLowerCase() === "boolean" : false;
}

export function isPassword(type: string) {
  return type ? type.toLowerCase() === "password" : false;
}

export function isDate(type: string) {
  return type ? type.toLowerCase() === "date" : false;
}

export function isFile(type: string) {
  return type ? type.toLowerCase() === "file" : false;
}

export function isObject(type: string) {
  return type ? type.toLowerCase() === "object" : false;
}

export function isArray(type: string) {
  return type ? type.toLowerCase() === "array" : false;
}

export function isSelect(type: string) {
  return type ? type.toLowerCase() === "select" : false;
}

export function isComboSelect(type: string) {
  return type ? type.toLowerCase() === "comboselect" : false;
}

export function isComboBox(type: string) {
  return type ? type.toLowerCase() === "combobox" : false;
}

export function isToggle(type: string) {
  return type
    ? type.toLowerCase() === "toggle" || type.toLowerCase() === "toggleselect"
    : false;
}
