import { html, PropertyValues } from "lit";
import { customElement, property, state } from "lit/decorators";
import { removeRecursiveByService } from "../common/array/delete_params";
import { tauiUrl } from "../data/auth";
import { fetchConfig } from "../data/config";
import { TauiElement } from "../state/taui-element";
import "../styles/polymer-taui-style";
import { TucanoAdminUI } from "../types";
import {
  registerServiceWorker,
  supportsServiceWorker,
} from "../util/register-service-worker";
import { clearState, storeState } from "../util/taui-pref-storage";
import "./taui-app-main";
import "./taui-init-page";
import { getCurrentPermission } from "../data/identity/permission";
import { getCurrentPermissionRG } from "../data/identity/oauth";
import { Service, TauiServices } from "../types/structure";
import { fireEvent } from "../common/dom/fire_event";
import { getMainError } from "../common/util/manager_error";
import {
  DEFAULT_ACCENT_COLOR,
  DEFAULT_PRIMARY_COLOR,
} from "../resources/taui-style";
import {
  removeLaunchScreen,
  renderLaunchScreenInfoBox,
} from "../util/launch-screen";

@customElement("taui-app")
export class TauiApp extends TauiElement {
  @state() private _panelUrl?: string;

  private _hiddenTimeout?: number;

  private _visiblePromiseResolve?: () => void;

  @property({ attribute: true }) public services: Service[] = [];

  protected renderTaui() {
    return html`
      <taui-app-main
        .taui=${this.taui}
        @route-changed=${this.routeChanged}
        @identity-changed=${this.identityChanged}
        @selected-panel-changed=${this.selectedPanelChanged}
        @tabs-changed=${this.tabsChanged}
      ></taui-app-main>
    `;
  }

  private _renderInitInfo(error: boolean) {
    renderLaunchScreenInfoBox(
      html`<taui-init-page .error=${error}></taui-init-page>`
    );
  }

  protected firstUpdated(changedProps) {
    super.firstUpdated(changedProps);

    this._initializeTaui();

    setTimeout(() => registerServiceWorker(this), 1000);

    this.addEventListener("taui-suspend-when-hidden", (ev) => {
      this._updateTaui({ suspendWhenHidden: ev.detail.suspend });
      storeState(this.taui!);
    });

    this.addEventListener("taui-enable-shortcuts", (ev) => {
      this._updateTaui({ enableShortcuts: ev.detail });
      storeState(this.taui!);
    });

    this.addEventListener("taui-refresh-config", async (_ev) => {
      let response;
      let config;

      try {
        response = await fetchConfig(tauiUrl);
        config = await response.json();
      } catch (err) {
        // eslint-disable-next-line
        console.error("Error loading config", err);
      }

      this._updateTaui({ config: config });
    });

    this.addEventListener("dynamic-column-changed", (ev) => {
      this._updateTaui({
        dynamicColumn: ev.detail,
      });
      storeState(this.taui!);
    });

    this.addEventListener("ids-upload-changed", (ev) => {
      this._updateTaui({
        idsUpload: ev.detail,
      });
      storeState(this.taui!);
    });

    // Render launch screen info box (loading data / error message)
    if (this.render !== this.renderTaui) {
      this._renderInitInfo(false);
    }
  }

  protected updated(changedProps: PropertyValues): void {
    if (changedProps.has("_panelUrl")) {
      this.panelUrlChanged(this._panelUrl!);
      this._updateTaui({ panelUrl: this._panelUrl });
    }
    if (changedProps.has("taui")) {
      this.tauiChanged(
        this.taui!,
        changedProps.get("taui") as TucanoAdminUI | undefined
      );
    }

    if (
      this.taui?.config &&
      this.taui?.services &&
      this.taui?.auth &&
      this.taui?.user &&
      this.taui?.currentPermissions &&
      this.taui?.currentPermissionsRG
    ) {
      this.render = this.renderTaui;
      this.update = super.update;
      removeLaunchScreen();
    }
    super.updated(changedProps);
  }

  private routeChanged(ev) {
    this._panelUrl = ev.detail;
  }

  private identityChanged(ev) {
    this._updateTaui({
      previousIdentity: ev.detail,
    });
    storeState(this.taui!);
  }

  private selectedPanelChanged(ev) {
    this._updateTaui({
      selectedPanel: ev.detail,
    });
    storeState(this.taui!);
  }

  private tabsChanged(ev) {
    this._updateTaui({
      tabs: ev.detail,
    });
    storeState(this.taui!);
  }

  protected async tauiConnected() {
    super.tauiConnected();

    document.addEventListener(
      "visibilitychange",
      () => this._checkVisibility(),
      false
    );
    document.addEventListener("freeze", () => this._suspendApp());
    document.addEventListener("resume", () => this._checkVisibility());

    let response;
    let config;

    try {
      // We prefetch this data on page load in authorize.html.template for modern builds
      response = await ((window as any).tauiConfig || fetchConfig(tauiUrl));

      if (!response.ok) {
        throw new Error(
          `Fail to fetch config: HTTP response status is ${response.status}`
        );
      }

      config = await response.json();
    } catch (err) {
      this._renderInitInfo(true);
      // eslint-disable-next-line
      console.error("Error loading config", err);
      return;
    }

    this._updateTaui({ app: this, config: config });

    this.checkTauiVersion(this.taui?.config?.components);

    this._updateTaui({ services: this.getServiceFromStructure() });

    fireEvent(this, "settheme", {
      primaryColor: `${
        config!.skin === "bee" ? "#ee7d00" : DEFAULT_PRIMARY_COLOR
      }`,
      accentColor: `${
        config!.skin === "bee" ? "#fab900" : DEFAULT_ACCENT_COLOR
      }`,
    });

    this.fetchConfigBackend();
  }

  async fetchConfigBackend() {
    await getCurrentPermission(this.taui!).then(
      (dataCurrentPermission) => {
        if (dataCurrentPermission && dataCurrentPermission.result) {
          dataCurrentPermission.result.sort((el1: any, el2: any) =>
            el1 < el2 ? -1 : 1
          );

          this._updateTaui({
            currentPermissions: dataCurrentPermission.result,
          });
        }
      },
      (err) => {
        this._renderInitInfo(true);
        if (err.error)
          setTimeout(() => {
            fireEvent(this as any, "taui-notification", {
              message: getMainError(this.taui, err),
              type: "error",
            });
          }, 1000);
      }
    );

    await getCurrentPermissionRG(this.taui!).then(
      (dataCurrentPermissionRG) => {
        if (dataCurrentPermissionRG && dataCurrentPermissionRG.result) {
          dataCurrentPermissionRG.result.sort((el1: any, el2: any) =>
            el1 < el2 ? -1 : 1
          );

          this._updateTaui({
            currentPermissionsRG: dataCurrentPermissionRG.result,
          });
        }
      },
      (err) => {
        this._renderInitInfo(true);
        if (err.error)
          setTimeout(() => {
            fireEvent(this as any, "taui-notification", {
              message: getMainError(this.taui, err),
              type: "error",
            });
          }, 1000);
      }
    );
  }

  protected checkTauiVersion(components) {
    const adminUiComponent = components?.find(
      (el) => el.displayName === "adminui"
    );

    // If backend has been upgraded, make sure we update frontend
    if (this.taui!.tauiVersion !== adminUiComponent?.version) {
      this._updateTaui({
        tauiVersion: adminUiComponent?.version,
      });
      storeState(this.taui!);

      if (supportsServiceWorker()) {
        navigator.serviceWorker.getRegistration().then((registration) => {
          if (registration) {
            registration.update();
          } else {
            location.reload();
          }
        });
      } else {
        location.reload();
      }
    }
  }

  protected tauiReconnected() {
    super.tauiReconnected();

    this.checkTauiVersion(this.taui?.config?.components);
  }

  protected async _initializeTaui() {
    try {
      let result;

      if (window.tauiConnection) {
        result = await window.tauiConnection;
      } else {
        // In the edge case that core.ts loads before app.ts
        result = await new Promise((resolve) => {
          window.tauiConnectionReady = resolve;
        });
      }

      const { config, auth, conn } = result;

      this.initializeTaui(config, auth, conn);
    } catch (err) {
      this._renderInitInfo(true);
      clearState(true);
    }
  }

  protected _checkVisibility() {
    if (document.hidden) {
      // If the document is hidden, we will prevent reconnects until we are visible again
      this._onHidden();
    } else {
      this._onVisible();
    }
  }

  private _onHidden() {
    if (this._visiblePromiseResolve) {
      return;
    }
    this.taui!.connection.suspendReconnectUntil(
      new Promise((resolve) => {
        this._visiblePromiseResolve = resolve;
      })
    );
    if (this.taui!.suspendWhenHidden !== false) {
      // We close the connection to TAUI after being hidden for 5 minutes
      this._hiddenTimeout = window.setTimeout(() => {
        this._hiddenTimeout = undefined;
        // setTimeout can be delayed in the background and only fire
        // when we switch to the tab or app again (Hey Android!)
        if (document.hidden) {
          this._suspendApp();
        }
      }, 300000);
    }
    window.addEventListener("focus", () => this._onVisible(), { once: true });
  }

  private _suspendApp() {
    if (!this.taui!.connection.connected) {
      return;
    }
    window.stop();
    this.taui!.connection.suspend();
  }

  private _onVisible() {
    // Clear timer to close the connection
    if (this._hiddenTimeout) {
      clearTimeout(this._hiddenTimeout);
      this._hiddenTimeout = undefined;
    }
    // Unsuspend the reconnect
    if (this._visiblePromiseResolve) {
      this._visiblePromiseResolve();
      this._visiblePromiseResolve = undefined;
    }
  }

  protected getServiceFromStructure(): Service[] {
    let servicesAccessible: Service[] = [];

    const baseServices = this.taui!.structure.baseServices;
    const services = this.taui!.structure.services;
    const components = this.taui!.config.components;
    const availableServices = [...(components || []), ...(baseServices || [])];

    const serviceItemDisabled = Object.values(TauiServices).filter(
      (service) => {
        const found = availableServices.find((availableService) =>
          availableService.displayName.includes(service)
        );
        return !found ? service : false;
      }
    );

    servicesAccessible = removeRecursiveByService(
      services,
      serviceItemDisabled
    );

    return Object.values(servicesAccessible);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "taui-app": TauiApp;
  }

  // for fire event
  interface TAUIDomEvents {
    "taui-enable-shortcuts": TucanoAdminUI["enableShortcuts"];
  }
}
